/*------------------------------------

Test Type Card

------------------------------------*/

.test-type-card {
	width: calc(25% - 10px);
	height: 230px !important;
	margin: 10px 5px 5px;
	box-shadow: 0 0 20px rgb(11 32 49 / 10%) !important;
	box-sizing: border-box;
	position: relative;
	padding: 20px;
	display: flex;
	transition: 0.1s ease-in-out;
	cursor: pointer;
	border: 3px solid white;
	color: #fff !important;
}

.test-type-card:hover {
    transform: scale(1.01);
}

.test-type-card .test-type-icon {
	width: 100%;
	height: 40px;
	justify-content: center;
	align-items: center;
	display: flex;
	padding: 10px 0;
	color: #3fbeb6;
}

.test-type-card h4 {
    margin: 0 0 10px 0;
    color: #333;
}

.test-type-card h6 {
	margin: auto 0 auto !important;
}

.test-type-card p {
	margin: 5px 0 0 !important;
	color: #ccc;
}

.card.create-new-test-type-block {
    border: 3px dashed #3fbeb6;
    position: relative;
}

.test-type-summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    flex-grow: 1;
}

.test-type-result {
    width: 50%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.test-type-result svg,
.test-type-card.create-new svg {
    width: 40px;
    height: 40px;
}


.test-type-card {
	background: #0b2031 !important;
	border-color: #3fbeb6;
	color: #fff;
}

.test-type-card.create-new {
    border: 3px dashed #3fbeb6;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.test-type-card.create-new h3 {
    width: 100%;
}

.test-type-card-result-indicator div {
    display: none;
}

.test-type-card-result-indicator svg {
    width: 100%;
    height: 40px;
    margin: 0 0 20px !important;
}

.test-type-card-result-totals .result-indicator div {
    display: none;
}

.test-type-card-result-totals .result-indicator svg {
    width: 20px;
    height: 20px;
}

.test-type-card-result-totals .MuiGrid-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.test-type-card-result-totals h6 {
    font-size: 1.5em !important;
}

@media (max-width: 1400px) {
    .test-type-card {
        width: calc(50% - 10px);
    }

    .test-result {
        min-width: auto;
    }
}

@media (max-width: 1000px) {
    .test-type-card {
        padding:10px;
    }
}

@media (max-width: 600px) {
    .test-type-card {
        height: 220px !important;
        width: calc(50% - 8px);
        margin: 4px;
    }

    .test-type-summary {
        padding: 0;
    }

    .test-type-card p {
        display: none;
    }
    
    .test-type-card .MuiTypography-h6 {
        font-size: 1em;
    }
    
    .test-type-card-result-totals {
        width: 100%;
        margin: 0 auto 0 -9px;
    }
    
    .test-type-summary {
        padding: 0;
    }
    
    .test-type-card-result-totals .result-indicator svg {
        margin-right: 5px !important;
    }
    
    .test-type-card-result-totals .MuiGrid-item {
        max-width: none;
    }
}