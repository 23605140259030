/*
----------------------------

Test Drawer

----------------------------
*/

.edit-test-drawer .MuiPaper-root {
    max-width: calc(100vw - 400px) !important;
}

.edit-test-drawer-inner {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	text-align: center;
	width: 75vw;
	min-height: 101vh;
}

.inner-metrics .test-type-icon {
    justify-content: center;
}

.inner-metrics .test-type-icon div {
    display: none;
}

.results-timeline-chart {
    margin-bottom: 10px;
}

.edit-test-action-buttons {
	position: fixed;
	bottom: 0;
	right: 17px;
	background: rgba(255,255,255,0.9);
	margin: 0 !important;
	padding: 10px;
	width: calc(75vw);
	z-index: 99;
	box-shadow: 0 0 20px rgba(0,0,0,0.05);
}

.test-action-buttons>div:nth-of-type(1), .test-action-buttons>div:nth-of-type(2) {
	flex-basis: 250px;
}

.test-action-buttons>div:nth-of-type(1) button,
.test-action-buttons>div:nth-of-type(2) button {
    width: 100%;
}

@media (max-width: 1500px) {
    .edit-test-action-buttons {
        width: calc(90vw);
    }
}

@media (max-width: 1200px) {
    .edit-test-action-buttons {
        width: 830px;
        max-width: calc(100vw - 60px);
    }
}

@media (max-width: 1000px) {
    .edit-test-action-buttons {
        width: calc(100vw - 50px);
        max-width: none;
        right: 0;
    }
    .test-action-buttons>div:nth-of-type(1), .test-action-buttons>div:nth-of-type(2) {
        flex-basis: 50%;
    }    
}

@media (max-width: 600px) {
    .test-action-buttons {
        width: 100vw;
    }
    .test-action-buttons > div:nth-of-type(3) {
        display: none;
    }
}