/*------------------------------------

General Styling

------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');

div {
    box-sizing: border-box;
}

.debug {
    display: none !important;
}

h1,
h2,
h3,
h4 {
    clear: both;
    line-height: 1.2em;
    margin-top: 1.5em;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.fancy-font {
    font-family: 'DM Serif Display', serif !important;
    font-weight: bold !important;
}

.unclickable {
    pointer-events: none !important;
}

.mark-as-pass {
    display: none !important; /*hidden until feature is improved to a score system */
}

.app {
    display: flex;
    width: 100%;
    background: #f6f5f3;
    padding: 16px;
    margin: 0 auto;
    min-height: 100vh;
}

.primary {
    flex-grow: 1;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 256px);
}

.main-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #f1eae3;
    border-radius: 15px 0;
    margin-top: 15px;
    flex-grow: 1;
    flex-direction: column;
    padding: 0 0 10px;
}

.main-content .page-title {
    margin: 30px 20px 0 20px;
    color: #0b2031;
}

.main-content .page-subtitle {
    margin: 15px 15px 20px 20px;
}

.app h6 {
    margin: 1.5em 0;
}

.profile {
    background-color: #f6f5f3;
    border-radius: 11px;
    margin: 15px 15px 15px;
    box-shadow: 0 0 20px rgb(11 32 49 / 1%);
    max-width: calc(100% - 30px) !important;
    height: 100%;
}

.delete-modal {
    text-align: center;
}

pre {
    text-align: left;
}

.circular-progress-no-bg {
    border-radius: 50%;
    box-shadow: inset 0 0 1px 5px rgba(0, 0, 0, 0);
    background-color: transparent;
}

.edit-user-form {
    max-width: 480px;
}

#root.drawer-open {
    filter: blur(2px);
}

.center-list {
    margin: 0 auto !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
}

.styled-skeleton {
    width: calc(100% - 30px) !important;
    height: calc(100% - 30px) !important;
    margin: 15px !important;
    border-radius: 5px !important;
}

.tests-container,
.projects-container,
.cards-container {
    display: flex;
    padding: 15px;
    width: calc(100% - 30px);
    flex-wrap: wrap;
    border-radius: 10px;
    background: #f6f5f3;
    margin: 15px;
}

.tests-container-controls {
    width: 100%;
    display: flex;
}

.run-tests-wrapper {
    flex-grow: 1;
}

.run-tests-wrapper p {
    padding-left: 110px;
    width: 11em;
}

.tests-filter {
    width: 800px;
    max-width: calc(100vw - 380px);
    padding: 20px;
}

.test-count {
    min-width: 130px;
}

.inner-metrics {
    width: 50%;
    background: #06141f;
    color: #fff;
    padding: 70px 30px !important;
}

.inner-config {
    width: 50%;
    padding: 70px 30px !important;
}

.create-test-types>.test-type-icon {
    width: 70px;
    height: 70px;
}

.inner-metrics h5 {
    margin-top: 1.5em;
    margin-bottom: 1em;
}

.card-uniform-height {
    display: flex;
    height: 100%;
}

.card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.cards-container .MuiPaper-rounded {
	border-radius: 10px 0 !important;
}

.cards-container .MuiPaper-rounded:nth-of-type(2n) {
	border-radius: 0px 10px !important;
}

.inner-metrics .card-content svg {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
}

.delete-button,
.close-button {
    position: absolute !important;
    top: 8px;
    z-index: 999;
}

.delete-button {
    right: 50px;
}

.close-button {
    right: 8px;
}

.test-type-details {
	padding: 20px;
	background: #0b2031;
	border-radius: 15px 0;
	border: 5px solid #3fbeb6;
	color: #fff;
}

.test-type-graphic {
	position: relative;
	left: -10%;
	max-width: 40vw;
	margin: 0 auto;
	display: block;
}

.project-selector-bar-avatar {
	position: absolute;
	top: 13px;
	right: 25px;
}

.account-buttons {
	display: flex;
	gap: 1em;
    margin-top: 20px;
}

video {
	max-width: 100%;
	height: auto;
}

@media (max-width:1500px) {
    .run-tests-wrapper {
        display: none !important;
    }

    .edit-test-drawer-inner {
        width: 90vw;
    }
}

@media (max-width: 1200px) {

    .inner-metrics,
    .inner-config {
        width: 100%;
    }

    .edit-test-drawer-inner {
        width: 830px;
        max-width: calc(100vw - 60px);
    }

    .delete-button,
    .close-button {
        top: 5px;
    }
}

@media (max-width:1000px) {
    body {
        padding-right: 0 !important;
        overflow: auto !important;
    }

    .app {
        padding: 0;
    }

    .primary {
        padding: 0;
    }

    .main-content {
        border-radius: 0;
        margin-top: 0;
        padding: 0 0 70px;
    }

    .tests-filter {
        flex-wrap: wrap;
        padding: 9px 6px;
        gap: 10px !important;
        width: auto;
        max-width: none;
    }

    .tests-filter>.MuiFormControl-root {
        min-width: auto;
        width: calc(50% - 10px);
    }

    .tests-filter>span:first-of-type {
        display: none;
    }

    .tests-filter>.MuiFormControl-root:first-of-type {
        margin-right: 0 !important;
    }

    .tests-container,
    .projects-container,
    .cards-container {
        padding: 6px;
        width: calc(100% - 16px);
        margin: 8px;
    }

    .run-tests-wrapper {
        display: none !important;
    }

    .test-count {
        min-width: auto;
    }

    .page-title {
        font-size: 25px !important;
        margin-bottom: 0;
    }

    .page-title button {
        padding: 6px;
    }

    .page-title button svg {
        width: 0.9em;
        height: 0.9em;
    }

    .test-count {
        min-width: auto;
    }

    .edit-test-drawer-inner {
        width: calc(100vw - 50px);
        max-width: none;
    }

    .inner-metrics {
        padding: 50px 20px 80px !important;
        min-height: calc(100vh - 50px);
    }

    .account-buttons {
        flex-wrap: wrap;
        flex-direction: column;
    }
    
    .account-buttons button {
        flex-grow: 1;
        background: #0b2031;
        color: #fff;
    }
}

@media (max-width: 600px) {
    .edit-test-drawer-inner {
        width: 100vw;
        max-width: none;
    }

    .main-content .page-title {
        margin: 40px 10px 0 10px;
        text-align: left;
    }
    
    .main-content .page-subtitle {
        margin: 15px;
    }

    .cards-container {
        margin: 0;
        width: 100%;
        border-radius: 0;
    }

    .project-selector-bar-avatar > div {
        width: 40px;
        height: 40px;
    }
    
    .project-selector-bar-avatar {
        top: 15px;
        right: 15px;
    }
}