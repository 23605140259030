/*
----------------------------

Results Usage

----------------------------
*/

.results-usage {
	background: #122f47;
	padding: 10px;
	text-align: center;
	border-radius: 10px 0;
}

.results-usage .MuiTypography-h7 {
	font-size: 0.9em;
}

.usage-type {
	position: relative;
	background: transparent !important;
	color: #fff !important;
	border: 2px solid #3fbeb6;
	overflow: visible !important;
	margin-top: 20px !important;
	margin-bottom: 10px;
	padding: 1vw;
}

.results-usage table {
	text-align: left;
	margin-top: 7px;
}

.results-usage td:nth-of-type(2) {
	width: 30px;
}

.results-usage td:nth-of-type(1) {
	width: 100%;
}

.usage-renewal {
	background: transparent;
	color: #fff;
	box-shadow: none;
	margin-top: 10px;
}

.usage-renewal p {
	font-size: 0.8em;
	display: none;
}

.results-usage-wrapper {
	position: absolute;
	bottom: 90px;
	width: 100%;
}

.usage-type:first-of-type {
	margin-right: 0 !important;
}

.usage-type .usage-type-icon {
	position: absolute;
	top: -16px;
	left: 50%;
	transform: translateX(-50%);
	height: 28px;
	background: #0b2031;
	padding: 0 10px;
	color: #3fbeb6;
}

.results-usage-details-modal .MuiDialog-paper {
    background: #0b2031;
    color: #fff;
}

.results-details-modal-inner {
	max-width: 440px;
	margin: 10px auto;
}

.results-details-modal-inner {
	max-width: 520px;
	margin: 10px auto;
}

.results-usage-details-modal .subtitle {
	margin-bottom: 1em;
}

.results-details-modal-inner td:nth-of-type(1) {
	width: 9em;
}

.results-details-modal-inner td {
	padding: 0.1em 0;
}

.results-usage-details-modal h4 {
	margin: 0.5em 0 0.75em;
}

.results-details-modal-inner td svg {
	width: 0.7em;
	margin-right: 0.3em;
}

.results-details-modal-inner table {
	margin-bottom: 1em;
}

.pricing-table {
	max-width: 920px !important;
}

.pricing-table td:nth-of-type(1) {
	width: 1em !important;
}

.pricing-table .usage-type {
	flex-basis: calc(33.33% - 10px);
}

.plan-select-button {
	margin-top: 20px !important;
	color: #fff !important;
	border-color: #fff !important;
	width: 100%;
}