/*------------------------------------

Project Selector

------------------------------------*/

.projects-selector {
	background: #0b2031;
	height: 100px;
	border-radius: 0 15px;
	display: flex;
	padding: 16px 8px;
	overflow-x: auto;
	overflow-y: hidden;
}

.projects-selector .project-selector-card {
	display: flex;
	align-items: center;
	min-width: 121px;
	height: 70px;
	border-radius: 0 8px;
	background: #f1a267;
	margin: 0 6px;
	text-align: center;
	justify-content: center;
	color: #fff;
	cursor: pointer;
	transition: 0.1s all ease-in-out;
	font-weight: 600;
	position: relative;
	padding: 0 10px;
}

.projects-selector-dropdown {
	background: #0b2031 !important;
    left: 10px !important;
}

.projects-selector-dropdown .Mui-selected {
	background: #3fbeb6 !important;
}

/*
.projects-selector .project-selector-card:after {
	width: 12px;
	height: 12px;
	position: absolute;
	background: #3fbeb6;
	top: -3px;
	right: -3px;
	content: '';
	border-radius: 50%;
	border: 4px solid #0b2031;
}
*/

.projects-selector .project-selector-card.active,
.projects-selector .project-selector-card:hover {
    transform: scale(1.03);
    background: #9b59b6;
}

.projects-selector .project-detail-card.add-project {
    background: #3fbeb6;
}

.project-selector-card h4 {
    font-size: 15px !important;
}

@media (max-width:1000px) {
    .projects-selector {
        color: #fff;
        height: auto !important;
        display: block;
        padding: 10px 100px 10px 10px !important;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        border-radius: 0;
        z-index: 99;
    }
    
    .project-title {
        font-size: 1.5em;
        text-align: center;
    }
}

@media (max-width: 600px) {
    .project-card {
        padding: 10px;
    }
    
    .project-card .MuiTypography-h6 {
        font-size: 1.1rem;
    }
    
    .project-card-result-totals {
        width: auto !important;
        padding: 0 !important;
        margin: 0 0 0 -21px !important;
    }
    
    .project-card-result-totals .MuiGrid-item:nth-of-type(2) {
        padding-left: 22px;
    }
    
    .project-card-result-totals .result-indicator svg {
        margin-right: 4px !important;
    }

    .main-content .project-title {
        margin: 5px 10px 0 10px !important;
    }
    
    .projects-selector {
        padding: 15px 70px 15px 15px !important;
    }
}