.results-link-details li {
	display: list-item;
	border: 1px solid #d4d4d4;
	padding: 10px;
}

.results-link-details li li {
	list-style: outside square;
	border: 0 none;
	margin-left: 2em;
	padding: 0;
}

.results-link-details li > p {
	margin-bottom: 0.5em;
}

.results-link-details li li p {
	margin: 0;
}

.results-link-details li > div > p {
	font-style: italic;
}

.results-timeline-chart-buttons {
	margin-bottom: 10px;
}

.results-timeline-chart-button {
	font-size: 0.7em;
}

.results-timeline-chart-wrapper {
	max-width: 650px;
	margin: 0 auto 20px;
}

@media (max-width: 600px) {
    .results-list h6 {
        flex-basis: 200px !important;
    }

    .results-list th, .results-list td {
        display: block;
    }
    
    .results-list th, .results-list td:nth-child(1) {
        background: #06141f;
        color: #fff;
    }
    
    .results-list table tr {
        border: 2px solid #06141f;
        border-radius: 5px !important;
        overflow: hidden;
        display: block;
        margin-bottom: 10px;
    }

    .result-timeline-accordion .MuiAccordionDetails-root {
        padding: 0 !important;
    }
}