.schedule-container {
	padding: 0 20px 20px;
	box-sizing: border-box;
	max-width: calc(100vw - 20px);
	overflow-x: auto;
}

.schedule-container h6 {
	margin-top: 40px;
}

.schedule-container th:first-of-type {
	width: 180px;
}

.schedule-container th:nth-of-type(2) {
	width: 240px;
}

.schedule-container th:nth-of-type(3) {
	width: 100px;
}

.schedule-container th:nth-of-type(5) {
	width: 70px;
}

.schedule-container th {
	font-weight: bold;
}

.schedule-container th:nth-of-type(4) {
	width: 200px;
}

.schedule-container th:nth-of-type(5) {
	width: 100px;
}

@media (max-width: 600px) {
    .schedule-container {
        max-width: 100vw;
    }
}
