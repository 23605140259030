/*------------------------------------

Summary Overview

------------------------------------*/

.summary {
    background-color: #f6f5f3;
    height: 270px;
    border-radius: 11px;
    margin: 15px 15px 15px;
    box-shadow: 0 0 20px rgb(11 32 49 / 1%);
    display: flex;
    padding: 0;
    overflow: hidden;
}

.summary-message {
    width: 33.33%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 40px;
    flex-direction: column;
    gap: 1em;
    background: #0b2031;
    color: #fff;
}

.summary-message svg {
    color: #fff;
}

.summary-message svg.success-icon {
    color: #3fbeb6;
}

.summary-message svg.fail-icon {
    color: #ff3468;
}

.summary>div {
    text-align: center;
}

.summary h6,
.summary-projects h6 {
    margin: 18px auto 14px !important;
    width: 100%;
    text-align: center;
}

.test-results-overview {
    width: calc(50% - 4vw - 180px);
    margin-left: 4vw;
    height: 270px;
    overflow: hidden;
}

.average-pagespeed-scores {
    width: calc(50% - 4vw - 180px);
    margin-right: 4vw;
    display: flex;
    flex-wrap: wrap;
}

.average-pagespeed-scores>div {
    margin: 0 auto;
    border: 0 none;
    border-radius: 50%;
    background: #3fbeb6;
    color: #fff;
    width: 175px;
    height: 175px;
    font-size: 2.2em;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.average-pagespeed-scores>div:first-of-type {
    margin-right: 5%;
}

.average-pagespeed-scores>div:last-of-type {
    margin-left: 5%;
}

.average-pagespeed-scores h6 {
    margin-bottom: -10px !important;
}

.average-pagespeed-scores h4 {
    margin: -80px auto 0;
    font-size: 18px;
    width: 100%;
}

.average-pagespeed-scores p {
    margin: 0px;
}

/*------------------------------------

Chart.js Styling

------------------------------------*/

.v_atp_chart_wrapper {
    height: 280px;
    overflow: hidden;
}

.test-results-overview>canvas {
    margin: auto;
    position: relative;
    top: -45px;
    left: -42px
}

@media (max-width: 1850px) {
    .summary {
        padding-right: 10px;
    }

    .test-results-overview,
    .average-pagespeed-scores {
        width: 33.33%;
        margin: 0;
    }

    .average-pagespeed-scores>div:last-of-type {
        margin-left: 0;
    }
}

@media (max-width: 1500px) {
    .summary-message {
        width: 100%;
        flex-direction: row;
        background: #0b2031;
        color: #fff;
    }

    .test-results-overview,
    .average-pagespeed-scores {
        width: 50%;
    }

    .summary {
        flex-wrap: wrap;
        height: auto;
        padding: 0;
        overflow: hidden;
    }

    .summary-message svg {
        font-size: 2em;
    }

    .summary-message button {
        margin: 0 !important;
    }
}

@media (max-width: 1200px) {
    .average-pagespeed-scores>div {
        width: 130px;
        height: 130px;
    }

    .average-pagespeed-scores h4 {
        margin-top: -60px;
    }
}

@media (max-width: 1000px) {

    .summary {
        margin: 8px;
    }

    .summary-message {
        padding: 10px;
        flex-wrap: wrap;
    }

    .summary-message button {
        width: 100%;
    }

    .summary-message svg {
        width: 30px;
    }

    .summary-message div {
        width: calc(100% - 90px);
    }

    .average-pagespeed-scores>div:last-of-type {
        margin-left: 0;
    }
}

@media (max-width: 600px) {
    .average-pagespeed-scores {
        display: none;
    }

    .test-results-overview {
        width: 100%;
    }

    .average-pagespeed-scores>div {
        margin: 0 !important;
        width: 50%;
        height: auto !important;
        background: transparent;
        color: #000;
        display: block;
    }

    .average-pagespeed-scores h4 {
        margin: 0;
        font-size: 14px;
    }

    .average-pagespeed-scores h2 {
        margin-bottom: 0;
    }

    .score-value {
        align-items: center;
        font-size: 30px;
        justify-content: center;
    }
}