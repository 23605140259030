/*------------------------------------

Project Card

------------------------------------*/

.project-card {
	background-color: #f6f5f3 !important;
	width: calc(25% - 10px);
	height: 230px !important;
	margin: 10px 5px 5px;
	border-radius: 11px;
	box-shadow: 0 0 20px rgb(11 32 49 / 10%) !important;
	box-sizing: border-box;
	position: relative;
	padding: 20px 20px 20px 10px;
	display: flex;
	transition: 0.1s ease-in-out;
	cursor: pointer;
	border: 3px solid white;
}

.project-card:hover {
    transform: scale(1.01);
}

.project-card h4 {
    margin: 0 0 10px 0;
    color: #333;
}

.project-card h6 {
	margin: auto 0 auto !important;
}

.project-card p {
    margin: 5px 0;
    color: #666;
}

.card.create-new-project-block {
    border: 3px dashed #3fbeb6;
    position: relative;
}

.project-summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    flex-grow: 1;
}

.project-result {
    width: 50%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.project-result svg,
.project-card.create-new svg {
    width: 40px;
    height: 40px;
}

.project-card.create-new {
    border: 3px dashed #3fbeb6;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.project-card.create-new h3 {
    width: 100%;
}

.project-card-result-indicator div {
    display: none;
}

.project-card-result-indicator svg {
    width: 100%;
    height: 40px;
    margin: 0 0 20px !important;
}

.project-card-result-totals .result-indicator div {
    display: none;
}

.project-card-result-totals .result-indicator svg {
    width: 20px;
    height: 20px;
}

.project-card-result-totals .MuiGrid-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-card-result-totals h6 {
    font-size: 1.5em !important;
}

@media (max-width: 1400px) {
    .project-card {
        width: calc(50% - 10px);
    }
}

@media (max-width: 600px) {
    .project-card {
        height: 220px !important;
        width: calc(50% - 8px);
        margin: 4px;
    }

    .test-type-summary {
        padding: 0;
    }

    .project-card p {
        display: none;
    }
    
    .project-card .MuiTypography-h6 {
        font-size: 1em;
    }
    
    .project-card-result-totals {
        width: 100%;
        margin: 0 auto 0 -9px;
    }
    
    .project-card-summary {
        padding: 0;
    }
    
    .project-card-result-totals .result-indicator svg {
        margin-right: 5px !important;
    }
    
    .project-card-result-totals .MuiGrid-item {
        max-width: none;
    }
}