/*
----------------------------

Project Drawer

----------------------------
*/

.edit-project-drawer .MuiPaper-root {
    max-width: calc(100vw - 400px) !important;
}

.edit-project-drawer-inner {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	text-align: center;
	width: 75vw;
	height: 100%;
}

.edit-project-drawer-inner > div {
	padding: 30px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.inner-metrics {
	width: 50%;
}

.inner-config {
	width: 50%;
	padding-left: 30px;
}

@media (max-width: 1000px) {
    .edit-project-drawer-inner .inner-metrics {
        min-height: auto !important;
    }
}

@media (max-width: 600px) {
    .edit-project-drawer-inner {
        width: auto;
    }
    
    .edit-project-drawer-inner .inner-metrics {
        display: none;
    }
    
    .edit-project-drawer-inner .inner-config {
        padding: 100px 20px 20px !important;
        justify-content: flex-start;
    }
    
    .edit-project-drawer-inner .inner-config form {
        text-align: left;
    }
    
    .edit-project-drawer-inner .inner-config form > label {
        margin-right: 0;
    }
    
    .edit-project-drawer-inner .inner-config form > div:last-of-type {
        text-align: center;
        margin-top: 10px;
    }
}