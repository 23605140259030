/*------------------------------------

Sidebar

------------------------------------*/

li.menu-item a {
    padding: 0.8em 0.4em;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 13px;
    display: block;
    background: #3fbeb6;
    font-size: 16px;
    border-radius: 8px 0;
    border-left: 0px solid #f1a267;
    border-right: 0px solid transparent;
    transition: 0.3s border-width ease-in-out;
    color: #fff;
    text-decoration: none !important;
}

.sidebar li a:hover,
.sidebar button:hover {
    border-left-width: 10px;
}

.sidebar {
    flex-basis: 256px;
    min-width: 256px;
    background: #0b2031;
    border-radius: 15px 0;
    padding: 6px 15px;
    box-sizing: border-box;
    margin: 0;
    box-shadow: 0px 1.1px 0.6px -11px rgba(0, 0, 0, 0.073), 0px 2.7px 1.5px -11px rgba(0, 0, 0, 0.105), 0px 5px 2.8px -11px rgba(0, 0, 0, 0.13), 0px 8.9px 4.9px -11px rgba(0, 0, 0, 0.155), 0px 16.7px 9.2px -11px rgba(0, 0, 0, 0.187), 0px 40px 22px -11px rgba(0, 0, 0, 0.26);
}

.sidebar ul {
    padding: 0;
    list-style: outside none;
    height: calc(100vh - 30px);
    position: sticky;
    margin: 0;
    top: 30px;
}

.sidebar li.current_page_item .sub-menu {
    display: block;
}

.sidebar li .sub-menu {
    display: none;
    padding: 0;
}

.sidebar li .sub-menu li a {
    background: #243746;
    margin: 0;
    border-radius: 0;
    font-size: 0.8em;
    border-left: 10px solid #f1a267;
    border-right: 10px solid transparent;
}

.sidebar li .sub-menu li {
    margin: 0;
}

.sidebar li.current_page_item a {
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-left-width: 10px;
    border-right-width: 10px;
    background: #243746;
}

.sidebar li .sub-menu li:last-of-type a {
    border-radius: 0 0 8px 0;
}

.sidebar-account {
    text-align: center;
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
    display: flex;
    gap: 10px;
}

.sidebar-avatar {
    cursor: pointer;
    transition: 0.2s ease-in-out;
    margin: 0 auto;
    display: block;
}

.sidebar-account button {
    background: #06141f !important;
    padding: 0.6em 0.4em;
    text-align: center;
    display: block;
    font-size: 16px;
    border-radius: 8px 0;
    border: 0 none;
    border-left: 4px solid #9b59b6;
    transition: 0.3s border-width ease-in-out;
    color: #fff;
    text-decoration: none !important;
    flex-grow: 1;
    cursor: pointer;
}

.menu-toggle {
	position: fixed !important;
	color: #fff !important;
	top: 17px;
	right: 10px;
}

.menu-bars {
	z-index: 999 !important;
}

.menu-close {
	z-index: 99999999999 !important;
}

.mobile-menu-drawer .MuiPaper-root {
	width: 380px !important;
	background: #0b2031;
	color: #fff;
	padding-top: 80px;
}

.mobile-menu-drawer a {
	text-align: right;
}

.menu-toggle svg[data-testid="CloseIcon"] {
	/*padding-right: 17px;*/
}

.mobile-menu-drawer .button__logout {
	background: #06141f !important;
	padding: 1em 0.6em;
	margin-left: 10px;
	text-align: center;
	display: block;
	font-size: 16px;
	border-radius: 8px 0;
	border: 0 none;
	border-left: 4px solid #9b59b6;
	transition: 0.3s border-width ease-in-out;
	color: #fff;
	text-decoration: none !important;
	flex-grow: 1;
	cursor: pointer;
}

.bottom-nav {
	position: fixed;
	bottom: 0;
	z-index: 99;
	width: 100%;
	background: #1c1c1e !important;
	height: 4.2em !important;
}

.bottom-nav-button {
	color: #98999d !important;
}

.bottom-nav-button.active {
	color: #f1a267 !important;
}

.bottom-nav-button svg {
	height: 1.3em;
	margin-bottom: 0.5em;
}