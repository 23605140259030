/*------------------------------------

Test Card

------------------------------------*/

.test-card {
	background-color: #f6f5f3 !important;
	width: calc(25% - 10px);
	height: 230px !important;
	margin: 10px 5px 5px;
	border-radius: 11px;
	box-shadow: 0 0 20px rgb(11 32 49 / 10%) !important;
	position: relative;
	padding: 20px 20px 20px 10px;
	display: flex;
	transition: 0.1s ease-in-out;
	cursor: pointer;
	border: 3px solid white;
    box-sizing: border-box;
}

.welcome-page .project-card, .welcome-page .test-card {
	width: 271px;
	margin: 20px auto;
}

.test-card:hover {
    transform: scale(1.01);
}

.test-card-innerwrap {
	display: flex;
	gap: 15px;
	max-width: 100%;
	overflow: hidden;
}

.test-card-title {
    margin: 0 0 10px 0;
    color: #333;
}

.test-card p {
    margin: 5px 0;
    color: #666;
}

.card.create-new-test-block {
    border: 3px dashed #3fbeb6;
    position: relative;
}

.test-summary {
	width: 100%;
	padding: 33px 10px 0;
	flex-grow: 1;
}

.test-summary svg {
    color: grey;
}

.test-result {
	min-width: 70px;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.test-result div {
    width: 100%;
    font-size: 0.9em;
}

.test-result svg,
.test-card.create-new svg {
    width: 100%;
    height: 40px;
    display: block;
    margin: 0 auto 10px !important;
}

.test-running svg {
    margin: 0 !important;
    width: auto;
    height: auto;
    margin: 0 !important;
}

.test-card.create-new {
    border: 3px dashed #3fbeb6;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.test-card.create-new h3 {
    width: 100%;
}

.test-type-icon {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0.5em;
}

.test-card-url {
    overflow: 'hidden';
    text-overflow: 'ellipsis';
    white-space: 'nowrap';
}

@media (max-width: 1600px) {
    .test-card {
        width: calc(33.33% - 10px);
    }
}
@media (max-width: 1400px) {
    .test-card {
        width: calc(50% - 10px);
    }
}
@media (max-width: 1000px) {
    .test-card-innerwrap {
        flex-direction: column;
        padding: 0;
        width: 100%;
    }
    
    .test-card {
        height: auto;
        padding: 25px 0px 15px;
    }

    .test-result {
        min-width: auto;
        width: 4em;
        margin: 0 auto;
    }
    
    .test-summary {
        padding: 0;
        text-align: center;
    }
    
    .test-type-icon {
        justify-content: center;
    }

    .environment-icons {
        justify-content: center;
    }

    .edit-button {
        display: none !important;
    }

    .test-card-url { 
        display:none;
    }
}

@media (max-width: 600px) {
    .test-card {
        width: calc(100% - 10px);
        padding: 10px 0 10px;
        height: auto !important;
    }
    
    .test-card-innerwrap {
        flex-direction: row;
        padding: 10px !important;
    }
    
    .test-type-icon {
        justify-content: left;
    }
    
    .test-card-title {
        text-align: left;
        white-space: normal !important;
        margin-top: 5px !important;
        line-height: 1.4em !important;
    }
    
    .test-card-url {
        display: block;
        text-align: left;
        max-width: calc(100vw - 140px);
    }
    
    .environment-icons {
        justify-content: left;
    }
    
    .test-result {
        width: 18%;
    }
    
    .test-summary {width: 82%;display: flex;flex-direction: column;justify-content: center;}
    
    .test-summary > div:last-of-type:empty {
        display: none;
    }
    
    .test-card.create-new h3 {
        margin-top: 0;
    }
    
    .test-card.create-new {
        padding-top: 25px;
    }
}